export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
  },
  {
    title: 'Total Votes',
    route: 'total-votes',
    icon: 'FileIcon',
  },
  {
    title: 'Vote Counter',
    route: 'total-votes-by-faculty',
    icon: 'FileIcon',
  },
  {
    title: 'Election',
    route: 'elections',
    icon: 'FileIcon',
  },
  {
    title: 'Candidate',
    route: 'candidates',
    icon: 'FileIcon',
  },
  {
    title: 'Campaigns',
    route: 'campaigns',
    icon: 'FileIcon',
  },
  {
    title: 'Recapulations',
    route: 'recapulation',
    icon: 'FileIcon',
  },
]
